import { Controller } from "@hotwired/stimulus";

const recordAudio = () =>
  new Promise(async (resolve) => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks = [];

    mediaRecorder.addEventListener("dataavailable", (event) => {
      audioChunks.push(event.data);
    });

    const start = () => mediaRecorder.start();

    const stop = () =>
      new Promise((resolve) => {
        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          const play = () => audio.play();
          resolve({ audioBlob, audioUrl, play });
        });

        if (mediaRecorder.state !== "inactive") {
          mediaRecorder.stop();
        }
      });

    resolve({ start, stop });
  });

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

var selector = null;
var audio = null;
var recorder = null;

// Connects to data-controller="audio-options"
export default class extends Controller {
  static targets = [
    "recordModal",
    "ttsModal",
    "audioPlay",
    "record",
    "stop",
    "save",
    "player",
    "loader",
  ];

  connect() {}

  open_modal(event) {
    event.preventDefault();
    selector =
      event.currentTarget.parentNode.parentNode.getElementsByClassName(
        "audio_file",
      )[0];
    if (event.currentTarget.dataset.modal) {
      // this.targets.find(event.currentTarget.dataset.modal).style.display = "block";
      this.targets
        .find(event.currentTarget.dataset.modal)
        .classList.remove("hidden");
    }
  }

  async close_modal(event) {
    event.preventDefault();
    if (recorder) {
      recorder.stop();
      recorder = null;
      const modal = event.currentTarget.dataset.modal;
      this.populate_result(modal, "");
      this.show_loader(modal, false);
    }
    event.currentTarget.closest(".sm-modal").classList.add("hidden");
  }

  async start_recording(event) {
    event.preventDefault();
    this.populate_result("recordModal", "");
    this.show_loader("recordModal", false);
    this.recordTarget.classList.add("btn-disabled");
    this.saveTarget.classList.add("btn-disabled");
    recorder = await recordAudio();
    recorder.start();
    this.populate_result("recordModal", "Recording Started");
  }

  async stop_recording(event) {
    event.preventDefault();
    if (recorder) {
      audio = await recorder.stop();
      this.populate_result("recordModal", "Recording Stopped");
      this.recordTarget.classList.remove("btn-disabled");
      this.stopTarget.classList.add("btn-disabled");
      this.saveTarget.classList.remove("btn-disabled");

      this.playerTarget.src = audio.audioUrl;
      this.playerTarget.type = "audio/mp3";
      this.playerTarget.load();
      // player.play()
    }
  }

  save_tts(event) {
    event.preventDefault();
    const data = new FormData();
    const openModal = this.targets.find("ttsModal");
    const text = openModal.querySelector("#tts_text").value;
    const gender = openModal.querySelector("#tts_gender").value;
    if (text !== "") {
      data.append("text", text);
      data.append("gender", gender);
      this.toggleLoader();
      this.sendForm(data, "/text-to-speech.json", "ttsModal");
    } else {
      openModal.querySelector(".result").innerHTML = "Text can not be blank.";
    }
  }

  save_recording(event) {
    event.preventDefault();
    if (!audio) {
      return;
    }
    var data = new FormData();
    var filename =
      new Date()
        .toISOString()
        .replace(new RegExp(":", "g"), "-")
        .replace(".", "-") + ".webm";
    // this.show_loader('recordModal', true)
    data.append("audio_file[upload]", audio.audioBlob, filename);
    this.sendForm(data, "/save_audio_blob.json", "recordModal");
  }

  populate_result(modal_name, message) {
    const openModal = this.targets.find(modal_name);
    if (openModal) {
      const res = openModal.querySelector(".result");
      res.innerHTML = `<span class='small-text text-alert-500'>${message}</span>`;
    }
  }

  toggleLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.toggle("hidden");
    }
  }

  show_loader(modal_name, state) {
    const openModal = this.targets.find(modal_name);
    if (openModal) {
      const loader = openModal.querySelector(".loader");
      loader.style.display = state;
    }
  }

  sendForm(data, path, modal) {
    return new Promise((resolve, reject) => {
      var audio_links, audio_file;
      const oReq = new XMLHttpRequest();
      oReq.open("POST", path);

      oReq.onload = (oEvent) => {
        this.toggleLoader();
        // this.show_loader(modal, false)
        if (oReq.status == 200) {
          this.populate_result(modal, "Audio File Created");
          const res = JSON.parse(oReq.response);
          audio_links = document.querySelectorAll("select.audio_file");
          Array.from(audio_links).forEach((drop) => {
            this.updateDropdown(res.audio_files, drop);
          });
          audio_file = res.audio_file;
          if (selector) {
            selector.value = audio_file.id;
            selector.tomselect.sync();
            let play_link =
              selector.parentNode.querySelectorAll(".play_audio_link")[0];
            play_link.id = audio_file.id;
            play_link.href =
              "/play_audio/" + audio_file.id + "?" + new Date().getTime();
          }
          resolve(res);
        } else {
          reject(this.populate_result(modal, `Error (${oReq.status})`));
        }
      };

      oReq.send(data);
    });
  }

  async duplicateAudio(event) {
    event.preventDefault();
    // Request new file
    const audio_hash = await this.sendForm(
      null,
      "/duplicate_file/" + event.currentTarget.dataset.audioId + ".json",
      null,
    );

    // Update table & player
    let player_frame = document.querySelector("turbo-frame#audio_player_frame");
    if (player_frame) {
      player_frame.src = `/update_player/${audio_hash.audio_file.id}`;
    }
    let audio_table_frame = document.querySelector(
      "turbo-frame#audio_file_list_frame",
    );
    if (audio_table_frame) {
      audio_table_frame.src = `/update_player/${audio_hash.audio_file.id}`;
    }
  }

  updateLink(event) {
    let play_link =
      event.target.parentNode.parentNode.parentNode.querySelectorAll(
        ".play_audio_link",
      )[0];
    play_link.id = event.target.dataset.value;
    play_link.href =
      "/play_audio/" + event.target.dataset.value + "?" + new Date().getTime();
  }

  updateDropdown(data, dropdown) {
    const current_val = dropdown.dataset.currentVal;
    const updateLink = this.updateLink;
    dropdown.innerHTML = "";
    data.forEach((audio) => {
      const option = document.createElement("option");
      option.value = audio.id == null ? "" : audio.id;
      option.innerHTML = audio.upload_file_name;
      dropdown.appendChild(option);
    });
    const blank = document.createElement("option");
    blank.value = "";
    blank.innerHTML = "Any";
    dropdown.prepend(blank);
    dropdown.value = current_val;
    if (dropdown.tomselect) {
      dropdown.tomselect.sync();
    } else {
      var settings = {
        render: {
          item: function (data) {
            const div = document.createElement("div");

            div.innerText = data.text;
            div.className = "item";
            div.addEventListener("click", updateLink, false);
            return div;
          },
        },
      };
      new TomSelect(dropdown, settings);
    }
  }
}
