import consumer from "./consumer";

document.addEventListener("turbo:load", () => {
  const isSubscribed = consumer?.subscriptions?.subscriptions
    ?.map((x) => JSON.parse(x.identifier)?.channel)
    ?.includes("WebNotificationsChannel");

  if (isSubscribed) {
    return;
  }
  consumer.subscriptions.create(
    { channel: "WebNotificationsChannel" },
    {
      connected() {
        console.log("connected to settings channel ");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        console.log("settings channel data:");
        console.log(data);

        const parsedData = typeof data === "string" ? JSON.parse(data) : data;

        $("#notification-icon").addClass("animate-pulse");
        $("#user_notifications").append(parsedData["message"]);
      },
    },
  );
});
