import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chat-vendors"
export default class extends Controller {
  static targets = ["vendorSelect", "genesysOptions", "awsOptions"];

  connect() {
    this.changeVendor();
  }

  changeVendor() {
    let value = this.vendorSelectTarget.value;
    document.querySelectorAll(".options").forEach((element) => {
      element.classList.add("hidden");
    });
    if (value == "Genesys") {
      this.genesysOptionsTarget.classList.remove("hidden");
    } else if (value == "AWS") {
      this.awsOptionsTarget.classList.remove("hidden");
    }
  }
}
