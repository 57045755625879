import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="api-explorer"
export default class extends Controller {
  static targets = [
    "commandFrame",
    "exampleCode",
    "commandDetails",
    "submitButton",
  ];

  showCommand(event) {
    event.preventDefault(); // Prevent the default link behavior
    this.commandFrameTarget.innerHTML = "<p>Loading...</p>";
    const url = event.target.dataset.url;
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html",
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (${response.status})`);
        }
        return response.text();
      })
      .then((html) => {
        this.commandFrameTarget.innerHTML = html;
      })
      .catch((error) => {
        console.error("Error loading partial:", error);
        this.commandFrameTarget.innerHTML = "<p>Failed to load content.</p>";
      });
  }

  sendRequest() {}

  updateExampleCode(event) {
    this.exampleCodeTarget.innerHTML = this.formatExample(event);
    this.submitButtonTarget.classList.remove("hidden");
  }

  formatExample(event) {
    const details = this.commandDetailsTarget.dataset;
    let endpoint = details.endpoint;
    if (event.target.dataset.type == "variable") {
      const variable_name = event.target.dataset.variable;
      endpoint = endpoint.replace(`:${variable_name}`, event.target.value);
    } else if (event.target.dataset.type == "param") {
      const url_param = event.target.dataset.param;
    }

    if ((details.method = "GET")) {
      return `
        fetch('${endpoint}', {
            method: ${details.method},
            headers: {
                'Content-Type': 'application/json',
                'Authorization: Bearer [YOUR API_KEY]'
                'Region: ${details.region}'
            },
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `;
    } else if ((details.method = "POST" || details.method == "PUT")) {
      return `
        fetch('${details.endpoint}', {
            method: ${details.method},
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(${details.body})
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `;
    }
  }
}
